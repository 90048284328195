import { combineReducers  } from 'redux';
import apiReducer from './apiReducer';
import categoryReducer from './categoryReducer';


const rootReducer = combineReducers({
  apiStore: apiReducer,
  categoryStore: categoryReducer,
});

export default rootReducer;
