import * as React from 'react';
import {default as cx} from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CreatableSelect from 'react-select/creatable';

import { getCategories } from 'actions/categoryActions'
import { getApis, createApi, updateApi, deleteApi, approveApi, denyApi } from 'actions/apiActions'
import { FiXCircle, FiCheckCircle, FiTrash2, FiEdit } from "react-icons/fi";

class SiteItem extends React.Component {
  constructor(props) {
    super(props);
    const site = props.site;
    this.state = {
      editName: site ? site.name : '',
      editUrl: site ? site.url : '',
      editDetails: site ? site.details : '',
      editCategory: site ? site.categories && Object.keys(site.categories) : [],
      editMode: site ? false : true,
      key: site ? null : 'create'
    }
  }

  componentDidMount() {
    this.props.getCategories();
  }

  createItem = () => {
    const {editName: name, editUrl: url, editDetails: details, editCategory} = this.state;
    let categories = editCategory;
    if (editCategory.length && typeof editCategory[0] !== 'string') {
      categories = [];
      editCategory.map((category) => categories.push(category.value))
    }
    this.props.createApi({name, url, details, categories}, this.props.categories);
    this.setState({ editName: '', editUrl: '', editCategory: [], editDetails: '' });
  }

  editItem = item => {
    this.setState({
      editMode: true,
    })
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editName: this.props.site.name,
    }));
  };

  onSaveEditText = () => {
    const { site } = this.props;
    const {editName: name, editUrl: url, editDetails: details, editCategory: categories} = this.state;
    this.props.updateApi(site, {name, url, details, categories}, this.props.categories)
    this.setState({ editMode: false });
  };

  onChangeEditText = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  onChangeSelect = arg => {
    if (!arg) {
      return this.setState({
        editCategory: []
      })
    }
    if (Array.isArray(arg)) {
      this.setState({
        editCategory: arg.map(item => item)
      })
    } else {
      this.setState({
        editCategory: this.state.editCategory ? [...this.state.editCategory, {label: arg, value: arg}] : [{label:arg, value:arg}]
      })
    }
  }

  createCategoryObject = (category) => {
    if (typeof category.count === 'number') {
      return {label:category.name, value:category.name};
    } else if (category.label) {
      return category;
    }
    return { label: category, value: category };
  }

  approveItem = item => {
    this.props.approveApi(item)
  }

  denyItem = item => {
    this.props.denyApi(item)
  }


  render() {
    const { site} = this.props;
    const { key, editMode, editName, editUrl, editCategory, editDetails } = this.state;

    const editCategoryObjectList = editCategory && editCategory.length ? editCategory.map(this.createCategoryObject) : [];
    const displayCategoryList = editCategoryObjectList.length ? editCategoryObjectList.map(category => <span key={`category-${category.value}`}>{category.label}{' '}</span>) : null;

    const categories = this.props.categories && this.props.categories.map(this.createCategoryObject);

    const classes = cx({
      'site': true,
      'approved': site && site.isApproved,
      'denied': site && site.isReviewed && !site.isApproved,
    })

    return (
      <div key={key ? key : site.uid} className={classes}>
        <div className="site-details">
          {editMode ? (
            <div>
              <div>
                <strong>Name:</strong>
                <input
                  type="name"
                  name="editName"
                  value={editName}
                  onChange={this.onChangeEditText}
                />
              </div>
              <div>
                <strong>Url:</strong>
                <input
                  type="name"
                  name="editUrl"
                  value={editUrl}
                  onChange={this.onChangeEditText}
                />
              </div>
              <div>
                <strong>Site Info:</strong>
                <input
                  type="name"
                  name="editDetails"
                  value={editDetails}
                  onChange={this.onChangeEditText}
                />
              </div>
              <div>
                <strong>Categories:</strong>
                <CreatableSelect
                  name="categories"
                  value={(editCategoryObjectList.length && editCategoryObjectList) || null}
                  isMulti
                  onChange={this.onChangeSelect}
                  onCreateOption={this.onChangeSelect}
                  options={categories}
                />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div>
                <strong>uid:</strong> {site.uid}
              </div>
              <div>
                <strong>Name:</strong> {site.name}
              </div>
              <div>
                <strong>Url:</strong> <a href={site.url.includes('http') ? site.url : `https://${site.url}`} target="_blank">{site.url}</a>
              </div>
              <div>
                <strong>Site Info:</strong> {site.details}
              </div>
              <div>
                <strong>Categories:</strong>
                {displayCategoryList}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="site-options">
          {editMode ? (
            <React.Fragment>
              <div onClick={key ? () => this.createItem() : () => this.onSaveEditText()}>Save</div>
              <div onClick={this.onToggleEditMode}>Reset</div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div onClick={() => this.editItem(site)}>
                <FiEdit />
              </div>
              { !site.isApproved && (
                <div onClick={() => this.approveItem(site)}>
                  <FiCheckCircle stroke={'#7AC74F'} />
                </div>
              )}
              { (!site.isReviewed || site.isApproved) && (
                <div onClick={() => this.denyItem(site)}>
                  <FiXCircle stroke={'#F25F38'} />
                </div>

              )}
              <div onClick={() => this.props.deleteApi(site, this.props.categories)}>
                <FiTrash2 stroke={'#F25F38'} />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  categories: state.categoryStore.categories,
});

const mapDispatchToProps = dispatch => ({
  getCategories: () => dispatch(getCategories()),
  updateApi: (site, apiItem, categories) => dispatch(updateApi(site, apiItem, categories)),
  createApi: (api, allCategories) => dispatch(createApi(api, allCategories)),
  deleteApi: (api, allCategories) => dispatch(deleteApi(api, allCategories)),
  approveApi: (api) => dispatch(approveApi(api)),
  denyApi: (api) => dispatch(denyApi(api)),
  getApis: () => dispatch(getApis()),
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SiteItem);
