import * as React from 'react';

import { withFirebase } from 'components/context';
import SiteItem from './SiteItem';
import './siteList.css';

class SiteList extends React.Component {

  render() {
    const { sites } = this.props;
    return (
      <div className="site-list">
        {sites.map(site => (
          <SiteItem key={site.uid} site={site} />
        ))}
      </div>
    );
  }
}

export default withFirebase(SiteList);

export { SiteItem }
