import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as ROLES from 'constants/roles';
import { withFirebase, withAuthorization } from 'components/context';
import { SiteList, SiteItem } from 'components/molecules';
import { getApis, createApi } from 'actions/apiActions';

import './admin.css';



class AdminPageBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      apis: [],
    };
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.apis) !== JSON.stringify(this.props.apis)) {
      this.setState({
        apis: this.props.apis.sort((a, b) => {
          if (a.isApproved && a.isReviewed) {
            return 1
          }
          if (a.isReviewed && !a.isApproved) {
            return 0
          }
          return -1
        }),
        loading: false,
      });
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getApis({ all: true });
  }

  handleChange = evt => this.setState({[evt.target.name]: evt.target.value})

  handleSubmit = evt => {
    const { name, url, categories, details } = this.state;
    this.props.createApi({name, url, details, categories})
    this.setState({ name: '', url: '', categories: '', details: '' });
    evt.preventDefault();
  }

  render() {
    const { loading, apis } = this.state;
    return (
      <div className="admin">
        <div className="admin-list">
          {loading && <div>Loading ...</div>}
          {<SiteItem />}
          {apis ? (
            <SiteList sites={apis} />
          ) : (
            <div>There are no sites to approve ...</div>
          )}
        </div>
      </div>
    );
  }
}

const condition = authUser => {
  return authUser && authUser.roles.includes(ROLES.ADMIN);
}

const mapStateToProps = state => ({
  apis: state.apiStore.apis,
});

const mapDispatchToProps = dispatch => ({
  createApi: (api) => dispatch(createApi(api)),
  getApis: (options) => dispatch(getApis(options))
});

const AdminPage = compose(
  withAuthorization(condition),
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AdminPageBase);

export default AdminPage;
