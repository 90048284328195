import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from 'components/context';
import { getCategories } from 'actions/categoryActions'
import { CategoryLink } from 'components/atoms';
import { CategoryDropdown } from 'components/molecules';
import { cloneDeep } from 'lodash';
import './categoryList.css'

class CategoryList extends React.Component {
  state = {
    categories: [],
  }

  componentDidMount() {
    this.props.getCategories();
  }

  render() {
    const {categories} = this.props;
    const allCategories = cloneDeep(categories).sort((a,b) => a.name.localeCompare(b.name));
    const popularCategories = cloneDeep(categories).sort((a,b) => b.count - a.count).splice(0,7)
    return (
      <div className="category-container">
        <CategoryDropdown categories={allCategories} />
        <div className="category-list">
          {popularCategories.map((category, idx) =>
            <CategoryLink category={category.name} key={idx} />)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categoryStore.categories,
});

function mapDispatchToProps(dispatch) {
  return {
    getCategories: () => dispatch(getCategories()),
  };
}

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CategoryList);

