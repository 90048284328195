import * as React from 'react';
import { Link } from 'react-router-dom';


import * as ROUTES from 'constants/routes';

const RegistrationLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Register</Link>
  </p>
);

export default RegistrationLink;
