import * as React from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import './searchBar.css';
import { withFirebase } from 'components/context';
import { searchApisAction, getApis } from 'actions/apiActions';
import { FiSearch } from "react-icons/fi";
import { trackEvent } from 'ga';
class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {searchValue: ''}
  }

  handleSubmit = () => {
    // fire filter apis action
    if (this.state.searchValue === '') {
      this.props.getApis();
      trackEvent('User', 'Clear Search via SearchBar')
    } else {
      trackEvent('User', 'Search', {label: `Search:${this.state.searchValue}`})
      this.props.searchApisAction(this.state.searchValue);
    }
    this.setState({searchValue:''});
  }

  handleChange = (event) => {
    this.setState({[event.currentTarget.name]: event.currentTarget.value});
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  }
  render() {
    return (
      <div className="search-bar">
        <div className='search-input'>
          <div>
            <input
              maxLength="2048"
              name="searchValue"
              type="text"
              aria-autocomplete="both"
              aria-haspopup="false"
              aria-controls=""
              aria-expanded="false"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              role="combobox"
              spellCheck="false"
              title="Search"
              placeholder="Search for an API..."
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              value={this.state.searchValue}
              aria-label="Search" />
          </div>
        </div>
        <div className="search-button" aria-label="Search" onClick={this.handleSubmit}>
          <FiSearch size={30} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  searchApisAction: (search) => dispatch(searchApisAction(search)),
  getApis: () => dispatch(getApis())
});


export default compose(
  withFirebase,
  connect(
    null,
    mapDispatchToProps,
  ),
)(SearchBar);
