import * as React from 'react';

import { RegistrationForm } from 'components/organisms';


const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <RegistrationForm />
  </div>
);

export default SignUpPage;
