import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';


import './index.css';
import reducers from 'reducers';
import { App } from 'components/pages';
import { Firebase, FirebaseContext } from 'components/context';
import * as serviceWorker from './serviceWorker';


const FirebaseInstance = new Firebase();
export default FirebaseInstance;
const store = createStore(reducers, {}, applyMiddleware(thunk, promise))

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={FirebaseInstance}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

