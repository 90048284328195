import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import { css } from 'emotion';

import ReactGA from 'react-ga';
import { CategoryLink } from 'components/atoms';


export default function ApiItem({api}) {

  const url = api.url.includes('http') ? api.url : `https://${api.url}`;
  const name = api.name || api.url;
  return (
    <div key={api.uid} className={baseStyle}>
      <div className={listItem}>
        <div className="list-content">
          <FiArrowRight className="list-icon" />
          <div className="list-details">
            <ReactGA.OutboundLink eventLabel="ApiClick" to={url} target='_blank' rel="noopener noreferrer">
              <div className="result-name">
                <span>{name}</span>
                <span className={dash}>-</span>
                <span className="result-url">{api.name && url}</span>
              </div>
              {api.details ? <div className={resultDetails}> {api.details} </div> : null }
            </ReactGA.OutboundLink>
          </div>
        </div>
      </div>
      <div className="category-list api-category">{api.categories && Object.keys(api.categories).map(category => <CategoryLink category={category}/>)}</div>
    </div>
  )
}

const baseStyle = css`
  padding: 30px 0px;
  border-bottom: 1px solid lightgrey;
  .result-name {
    font-size: 28px;
    display: flex;
    align-items: center;
    font-weight: 300;
    &:hover {
      color: #45ABFF;
    }
  }
  .result-url {
    margin-right: 10px;
    font-size: 19px;
    align-items: center;
    font-style: italic;
  }
  @media only screen and (max-width: 600px) {
    .result-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .result-url {
      overflow: hidden;
      overflow-wrap: break-word;
      word-wrap: break-word;
      padding: 10px 0 0 0;
    }
  }
`
const listItem = css`
  a {
    text-decoration: none;
    color: rgba(89, 88, 98, 1);
  }
  .list-icon {
    transition: all ease-in-out .25s;
    opacity: 0;
    margin: 0;
  }
  > .list-content {
    margin-left: 0;
    color: initial;
  }
  .list-content {
    display: flex;
    align-items: center;
  }
  .list-details {
    padding: 20px 0;
    overflow: hidden;
    .result-url {
      text-overflow: ellipsis;
    }
  }
  &:hover {
    * {
      color: #45ABFF;
      cursor: pointer;
    }
    .list-icon {
      transition: all ease-in-out .25s;
      opacity: 1;
      margin: 0.5em;
    }
  }
`
const dash = css`
  display: block;
  padding: 0 10px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const resultDetails = css`
  display: flex;
  font-weight: 300;
  padding-top: 10px;
  font-size: 16px;
  align-items: center;
`
