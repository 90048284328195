import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import './landingPage.css';

import { trackEvent } from 'ga';
import { CategoryList } from 'components/molecules';
import { SearchBar } from 'components/molecules';
import { withFirebase } from 'components/context';
import { getApis } from 'actions/apiActions';
import { FiXCircle } from "react-icons/fi";

import { ApiItem } from './components/ApiItem'

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      apis: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getApis();
    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    this.props.firebase.apis().off();
  }

  handleClear = () => {
    //this.props.getApis({onlyApproved: true});
    trackEvent('User', 'Clear Search via x')
    this.props.getApis();
  }

  render() {
    const { apis, filteredApis, searchTerm } = this.props;
    const displayApis = filteredApis ? filteredApis : apis;
    const showClear = <FiXCircle className="click xcircle" onClick={this.handleClear} />;
    return displayApis ? (
      <div>
        <div className="content-details">
          <div>
            <SearchBar />
            <CategoryList />
          </div>
        </div>
        <div className="results">
          <div className="results-count">{filteredApis ? <div>{displayApis.length} results found for <span className="searchterm"><span>{searchTerm}</span> {showClear}</span></div> : null}</div>
          <div className="api-list">
            {displayApis.map(api => <ApiItem api={api} />)}
          </div>
        </div>
      </div>
    ) : null;
  }
};


const mapStateToProps = state => ({
  apis: state.apiStore.apis,
  filteredApis: state.apiStore.filteredApis,
  searchTerm: state.apiStore.searchTerm
});

function mapDispatchToProps(dispatch) {
  return {
    getApis: (options) => dispatch(getApis(options)),
  };
}

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LandingPage);

