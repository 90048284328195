import FirebaseInstance from 'index';
import { updateCategory, addCategory } from './categoryActions';
export const GET_APIS = 'GET_APIS';
export const SEARCH_APIS = 'SEARCH_APIS';
export const FILTER_APIS = 'FILTER_APIS';

function capitalize(text) {
  return text.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}


export function updateApi(site, apiItem, allCategories) {
  return (dispatch) => {
    const { name, url, categories, details } = apiItem;
    const categoryObj = {};
    const removedCategories = site.categories || {};
    categories.length && categories.forEach(category => {
      let categoryName = category.value ? category.value : category;
      const currentCategory = allCategories.find(apiCategory => apiCategory.name.toLowerCase() === categoryName.toLowerCase());
      removedCategories[categoryName] && delete removedCategories[categoryName];
      if (currentCategory) {
        // update existing
        updateCategory(currentCategory, true);
      } else {
        // add new category
        addCategory(capitalize(categoryName));
      }
      categoryObj[capitalize(categoryName)] = true
    });
    Object.keys(removedCategories).forEach(remove => {
      let removeCat = allCategories.find(apiCategory => apiCategory.name.toLowerCase() === remove.toLowerCase())
      updateCategory(removeCat);
    });
    FirebaseInstance.api(site.uid).set({
      ...site,
      name,
      url,
      details,
      categories: categoryObj,
    })
  }
}

export function approveApi(site) {
  return (dispatch) => {
    FirebaseInstance.api(site.uid).set({
      ...site,
      isApproved: true,
      isReviewed: true,
    })
  }
}

export function denyApi(site) {
  return (dispatch) => {
    FirebaseInstance.api(site.uid).set({
      ...site,
      isApproved: false,
      isReviewed: true,
    })

  }
}

export function createApi(apiItem, allCategories) {
  return (dispatch) => {
    const { name, url, categories, details } = apiItem;
    const categoryObj = {};
    categories.forEach(category => {
      const currentCategory = allCategories.find(apiCategory => apiCategory.name.toLowerCase() === category.toLowerCase());
      if (currentCategory) {
        // update existing
        updateCategory(currentCategory, true);
      } else {
        // add new category
        addCategory(capitalize(category));
      }
      categoryObj[capitalize(category)] = true
    })
    FirebaseInstance.apis().push({
      name,
      url,
      categories: categoryObj,
      details,
      isReviewed: false,
      isApproved: false,
    });
  }
}

export function getApis(options = {}) {
  return (dispatch) => {
    FirebaseInstance.apis().orderByChild('name').on('value', snapshot => {
      // https://stackoverflow.com/a/52376603
      const apisObject = {}
      snapshot.forEach(child => {
        const childVal = child.val()
        const isValidApi = options.onlyApproved ?
          childVal.isApproved && child.isReviewed : !(!childVal.isApproved && childVal.isReviewed)
        if (isValidApi) {
          apisObject[child.key] = ({
            ...childVal
          })
        } else if (options.all) {
          apisObject[child.key] = ({
            ...childVal
          })
        }
      })
      dispatch(getApisAction(apisObject));
    })
  }
}

export function getApisAction(apis) {
  return {
    type: GET_APIS,
    apis
  };
}

export function filterApis(category) {
  return (dispatch) => {
    FirebaseInstance.apis()
      .orderByChild(`categories/${category}`)
      .equalTo(true)
      .on('value', snapshot => {
        const apisObject = {}
        snapshot.forEach(child => {
          const childVal = child.val()
        const isValidApi = !(!childVal.isApproved && childVal.isReviewed)
        if (isValidApi) {
            apisObject[child.key] = ({
              ...childVal
            })
          }
        })
        dispatch(filterApisAction(apisObject, category));
    });
  }
}


export function deleteApi(site, allCategories) {
  const {categories} = site;
  return (dispatch) => {
    Object.keys(categories).forEach(category => {
      const currentCategory = allCategories.find(apiCategory => apiCategory.name.includes(category));
      updateCategory(currentCategory);
    });
    FirebaseInstance.api(site.uid).remove();
  }
}

export function filterApisAction(filteredApis, category) {
  return {
    type: FILTER_APIS,
    payload: { filteredApis, category }
  };
}

export function searchApisAction(search) {
  return {
    type: SEARCH_APIS,
    search
  };
}
