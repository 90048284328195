import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { trackEvent } from 'ga';
import { withFirebase } from 'components/context';
import { filterApis } from 'actions/apiActions'
import './CategoryLink.css';
class CategoryLink extends React.Component {
  state = {};
  handleClick = (e) => {
    trackEvent('User', 'Category Filter', {label: `Category Filter:${e.target.dataset.textValue}`})
    this.props.filterApis(e.target.dataset.textValue);
  }

  render() {
    return <p className={this.props.li && 'li'} onClick={this.handleClick} data-text-value={this.props.category}>{this.props.category}</p>
  }

}

function mapDispatchToProps(dispatch) {
  return {
    filterApis: (category) => dispatch(filterApis(category)),
  };
}
export default compose(
  withFirebase,
  connect(
    null,
    mapDispatchToProps,
  ),
)(CategoryLink);
