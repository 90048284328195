import { GET_APIS, SEARCH_APIS, FILTER_APIS } from 'actions/apiActions';
const INITIAL_STATE = {
  apis: null,
  filteredApis: null
};

function mapToArr(apis) {
 return Object.keys(apis || {}).map(key => ({
    ...apis[key],
    uid: key,
  }))
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_APIS:
      return Object.assign({}, state, {apis: mapToArr(action.apis), filteredApis: null, searchTerm: null });
    case FILTER_APIS:
      let filteredApis = [];
      action.payload.filteredApis && Object.keys(action.payload.filteredApis).map(api => filteredApis.push(action.payload.filteredApis[api]));
      return Object.assign({}, state, { filteredApis, searchTerm: action.payload.category });
    case SEARCH_APIS:
      const search = action.search.toLowerCase();
      return Object.assign({}, state,
        { filteredApis: state.apis.filter(api =>
            (api.name && api.name.toLowerCase().includes(search)) || api.url.toLowerCase().includes(search)
            || (api.details && api.details.toLowerCase().includes(search))),
          searchTerm: action.search
        }
      );
    default:
      return state;
  }
}
