import FirebaseInstance from 'index';

export const GET_CATEGORIES = 'api/getCategories';

function getCategoriesAction(categories) {
  return {
    type: GET_CATEGORIES,
    categories,
  };
}

export function getCategories() {
  return (dispatch) => {
    FirebaseInstance.categories().on('value', snap => {
      const apiCategories = snap.val()
      let categories= [];
      Object.keys(apiCategories).forEach((categoryObj, index) => {
        categories.push({
          id: categoryObj,
          ...apiCategories[categoryObj]
        });
      });
      dispatch(getCategoriesAction(categories))
    })
  }
}

export function updateCategory(category, isPlus) {
    FirebaseInstance.categories().update({
      [category.id]: {
        name: category.name,
        count: isPlus ? category.count +=1 : category.count -=1
      }
    });
}

export function addCategory(category) {
    FirebaseInstance.categories().push({
      name: category,
      count: 1
    });
}
