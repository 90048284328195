import React, { useState } from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { CategoryLink } from 'components/atoms';

export default function CategoryDropdown({categories}) {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ButtonDropdown className="click" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="dropdown-text" caret={isOpen}>
        {isOpen ? 'All' : 'Most popular:'}
      </DropdownToggle>
      { isOpen && (
        <DropdownMenu className="category-dropdown">
          {categories.map((category, idx) => {
              return <DropdownItem><CategoryLink li category={category.name} key={`li-${idx}`}/></DropdownItem>
            })
          }
        </DropdownMenu>
      )}
    </ButtonDropdown>
  )
}
