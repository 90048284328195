import React from 'react';
import { NavLink } from 'react-router-dom';

import { SignOutButton } from 'components/molecules';
import { AuthUserContext } from 'components/context';
import * as ROUTES from 'constants/routes';
import * as ROLES from 'constants/roles';

import './nav.css';

const Navigation = ({ authUser }) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = ({authUser}) => (
  <div className="nav">
    <NavLink exact activeClassName='active' to={ROUTES.LANDING}>Home</NavLink>
    {authUser.roles.includes(ROLES.ADMIN) && (
      <NavLink activeClassName='active' to={ROUTES.ADMIN}>Admin</NavLink>
    )}
    <SignOutButton />
  </div>
);

const NavigationNonAuth = () => (
  <div className="nav">
      <NavLink exact activeClassName='active' to={ROUTES.LANDING}>Home</NavLink>
      <NavLink activeClassName='active' to={ROUTES.SIGN_IN}>Sign In</NavLink>
  </div>
);

export default Navigation;
