import * as React from 'react';
import {
  Router,
  Route,
} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory'
import { compose } from 'recompose';
import ReactGA from 'react-ga';
import * as ROUTES from 'constants/routes';
import { withAuthentication, AuthUserContext } from 'components/context';
import { Navigation } from 'components/organisms';
import {
  LandingPage,
  AdminPage,
  RegistrationPage,
  SignInPage,
} from 'components/pages';

import './app.css';
import "typeface-roboto";

const history = createHistory();
ReactGA.initialize('UA-143667481-1');
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

const AppBase = (props) => {
  return (
  <Router history={history}>
    <AuthUserContext.Consumer>
    {authUser => (
      <div className="content">
        {authUser && <Navigation />}
        <div>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.SIGN_UP} component={RegistrationPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
        </div>
      </div>
    )}
    </AuthUserContext.Consumer>
  </Router>
)};


const App = compose(
  withAuthentication,
)(AppBase)

export default App;
